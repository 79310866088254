import React, {useState} from 'react';
import './OptionBond.css';
import './Common.css';
import TimeStep from "./Input/TimeStep.js";
import ContractInfo from "./Input/ContractInfo.js";
import YtmTable from "./Input/YtmTable.js";
import Volatility from "./Input/Volatility.js";
import OptionContractInfo from "./Input/OptionContractInfo.js";
import {addCommasToNumber, usePreventNegativeInput} from "./Utils/NumberFormatUtils.js";
import {processTableData} from "./Utils/TableUtil.js";
import {validateContractDate, validateOptionDate} from "./Utils/Validator.js";

const OptionBond = () => {
    usePreventNegativeInput()
    const [ytmTable, setYtmTable] = useState([
        {period: 0.5, rate: 0},
        {period: 1.0, rate: 0},
        {period: 1.5, rate: 0},
        {period: 2.0, rate: 0},
        {period: 2.5, rate: 0},
        {period: 3.0, rate: 0},
        {period: 3.5, rate: 0},
        {period: 4.0, rate: 0},
        {period: 5.0, rate: 0}
    ]);

    const [responseData, setResponseData] = useState({});
    const [inputValidation, setInputValidation] = useState({});
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");


    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage("");
        setInputValidation(undefined)

        const form = event.target;
        const formData = new FormData(form);
        const jsonData = {};

        const optionData = {};
        const contractData = {};

        for (let [key, value] of formData.entries()) {
            if (['ytm-tablePeriod[]', 'ytm-tableRate[]'].includes(key)) {
                continue
            }
            if (key.includes('option-')) {
                let optionKey = key.replace("option-", "");
                if (optionKey.includes("PUT")) {
                    optionData[optionKey.replace("PUT-", "")] = value;
                } else if (optionKey.includes("CALL")) {
                    optionData[optionKey.replace("CALL-", "")] = value;
                } else if (optionKey.includes("undefined")) {
                    optionData[optionKey.replace("undefined-", "")] = value;
                }
                continue
            } else if (key.includes('contract-')) {
                contractData[key.replace("contract-", "")] = value;
                continue
            }

            if (jsonData[key]) {
                if (!Array.isArray(jsonData[key])) {
                    jsonData[key] = [jsonData[key]];
                }
                jsonData[key].push(value);
            } else {
                jsonData[key] = value;
            }
        }

        jsonData.optionContract = optionData
        jsonData.bondContract = contractData
        jsonData.ytmTable = processTableData(ytmTable);

        const validationResult = validateInput(jsonData)
        setInputValidation(validationResult)
        if (!validationResult.valid) {
            console.log('validation error');
            setLoading(false);
            return;
        }

        try {
            // const response = await fetch('https://api.jisungvalue.com/jsvaluation/optionBondValuation', {
            const response = await fetch('https://api.project-porto.com/jsvaluation/optionBondValuation', {
                // const response = await fetch('http://localhost:8080/jsvaluation/optionBondValuation', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            });

            if (!response.ok) {
                if (response.status >= 400 && response.status < 500) {
                    setErrorMessage("클라이언트 오류 발생: 요청을 확인하세요.");
                } else if (response.status >= 500) {
                    setErrorMessage("서버 오류 발생: 잠시 후 다시 시도하세요.");
                }
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setResponseData(responseData);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setResponseData({error: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="optionbond">
            <div className="optionbond-container">
                <div className="form-wrapper">
                    <form id="evaluationForm" onSubmit={handleSubmit}>
                        <div className="optionbond-input-area">
                            <h2>조기상환부사채/수의상환사채 평가</h2>
                            <ContractInfo/>
                            <br/>
                            <OptionContractInfo/>
                            <br/>
                            <Volatility/>
                            <br/>
                            <TimeStep/>
                            <br/>
                        </div>
                        <div className="optionbond-ytm-area">
                            <YtmTable
                                tableData={ytmTable}
                                setTableData={setYtmTable}
                                tableName="ytm-table"
                                tableTitle="위험할인율(YTM)"
                            />
                        </div>
                        <button type="submit">평가하기</button>
                    </form>
                </div>

                <div className="response-data-wrapper">
                    <div className="response-data">
                        <h2>평가 결과</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {(inputValidation.valid === undefined) ? "" :
                                        addCommasToNumber(responseData.optionBondFairValue)
                                    }
                                </td>
                            </tr>
                            {inputValidation.valid !== undefined && !inputValidation.valid && (
                                <tr>
                                    <td className="error-message">
                                        {"오류: " + inputValidation.errorMessage}
                                    </td>
                                </tr>
                            )

                            }
                            {errorMessage !== "" && (
                                <tr>
                                    <td className="error-message">
                                        {errorMessage}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <h2>평가 모형 | BDT(Black-Derman-Toy) 모형</h2>
                        <h3>참조사항</h3>
                        <ul>
                            <li>조기상환권(풋옵션) 가치
                                <br/>
                                = 조기상환부사채(풋옵션부채권)의 현재가치 - 일반채권의 현재가치
                            </li>
                            <li>수의상환권(콜옵션) 가치
                                <br/>
                                = 수의상환사채(콜옵션부채권)의 현재가치 - 일반채권의 현재가치
                            </li>
                            <li>본 사이트는 상기 평가 결과에 대하여 어떠한 책임도 지지 않습니다.</li>
                        </ul>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="loading-screen">
                    <div className="progress-bar">
                        <div className="progress"></div>
                    </div>
                    <p>계산 중 입니다. 잠시 기다려 주세요.</p>
                </div>

            )}
        </div>
    );
};


function validateInput(jsonData) {
    let [valid, invalidMessage] = validateContractDate(jsonData.bondContract)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    [valid, invalidMessage] = validateOptionValue(jsonData.bondContract, jsonData.optionContract)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    return {"valid": valid, "errorMessage": invalidMessage}
}

function validateOptionValue(contract, option) {
    let valid = true
    let invalidMessage = ""
    let optionName;
    if (option.type === "PUT") {
        optionName = "조기상환청구권"
    } else {
        optionName = "수의상환권"
    }
    [valid, invalidMessage] = validateOptionDate(contract, option, optionName);
    return [valid, invalidMessage]
}

export default OptionBond;
