import React from 'react';

function ContactPage() {
    return (
        <div>
            <ul>
                <li>공인회계사 황대리</li>
                <li>pporto2023@naver.com</li>
            </ul>
        </div>
    );
}

export default ContactPage;
