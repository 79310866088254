export function validateContractDate(contract) {

    if (contract.startDate === undefined) {
        return [false, "계약일자가 필요합니다"]
    } else if (contract.endDate === undefined) {
        return [false, "만기일자가 필요합니다"]
    } else if (contract.valuationDate === undefined) {
        return [false, "평가기준일이 필요합니다"]
    }

    if (!isValidDate(contract.startDate)) {
        return [false, "계약일자의 날짜 형식을 확인 해 주세요"]
    } else if (!isValidDate(contract.endDate)) {
        return [false, "만기일자의 날짜 형식을 확인 해 주세요"]
    } else if (!isValidDate(contract.valuationDate)) {
        return [false, "평가기준일의 날짜 형식을 확인 해 주세요"]
    }

    if (contract.startDate > contract.endDate) {
        return [false, "계약일자는 만기일자보다 미래 일 수 없습니다"]
    } else if (contract.startDate === contract.endDate) {
        return [false, "계약일자와 만기일자는 같은 날 일 수 없습니다"]
    } else if (contract.startDate > contract.valuationDate) {
        return [false, "계약일자는 평가기준일 보다 미래 일 수 없습니다"]
    } else if (contract.valuationDate > contract.endDate) {
        return [false, "평가기준일은 만기일자보다 미래 일 수 없습니다"]
    } else {
        return [true, ""]
    }
}

export function validateOptionDate(contract, option, title) {

    if (!isValidDate(option.startDate)) {
        return [false, title + " 행사 시작 일자의 날짜 형식을 확인 해 주세요"]
    } else if (!isValidDate(contract.endDate)) {
        return [false, title + "행사 종료 일자 날짜 형식을 확인 해 주세요"]
    }

    if (contract.startDate > option.startDate) {
        return [false, "계약일자가 " + title + " 행사시작일자 보다 미래일 수 없습니다"]
    } else if (option.startDate > option.endDate) {
        return [false, title + " 행사시작일자가 행사종료일자 보다 미래일 수 없습니다"]
    } else if (option.endDate > contract.endDate) {
        return [false, title + " 행사종료일자가 계약종료일자 보다 미래일 수 없습니다"]
    } else if (option.endDate < contract.startDate) {
        return [false, title + " 행사종료일자가 계약일자 보다 과거일 수 없습니다"]
    } else {
        return [true, ""]
    }
}

function isValidDate(dateString) {
    const datePattern = /^\d{4}-\d{2}-\d{2}$/;
    if (!datePattern.test(dateString)) return false;

    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
}