import React from 'react';
import '../Common.css'

const TimeStep = () => {
    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <td className="key">평가기준 노드간격</td>
                    <td colSpan="2">
                        <select name="timeStep" required>
                            <option value="WEEK">WEEK</option>
                            <option value="MONTH">MONTH</option>
                        </select>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default TimeStep;