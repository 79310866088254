import React from 'react';
import NumberInput from "./NumberInput.js";
import CustomDateInput from "./CustomDate.js";

const BondWithWarranty = ({optionName, needConvertibleRatio}) => {
    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <th colSpan="3">{"옵션 정보 ("+optionName+")"}</th>
                </tr>
                <tr>
                    <td className="key">행사시작일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="bw-startDate" defaultValue="2024-07-01" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">행사종료일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="bw-endDate" defaultValue="2024-07-02" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">1주당 행사가액</td>
                    <td colSpan="2">
                        <NumberInput  name="bw-price" defaultValue="1000"/> 원
                    </td>
                </tr>
                <tr>
                    <td className="key">행사가능주기</td>
                    <td colSpan="2">
                        <select name="bw-frequency" required>
                            {/* Always 인 경우 frequency = timeStep */}
                            {/*<option value="ALWAYS">항상</option>*/}
                            <option value="WEEKLY">주</option>
                            <option value="MONTHLY">월</option>
                            <option value="QUARTERLY">분기</option>
                            <option value="ANNUALLY">연</option>
                        </select>
                    </td>
                </tr>
                {
                    needConvertibleRatio === true && (
                        <tr>
                            <td className="key">우선주 1주당 전환주식수</td>
                            <td colSpan="2">
                                <NumberInput name="convertibleRatio" required defaultValue="1"/> 주
                            </td>
                        </tr>
                    )
                }
                </tbody>
            </table>
        </div>
    )
}

export default BondWithWarranty