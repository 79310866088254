import React from 'react';
import './ValuationSidebar.css';

const ValuationSidebar = ({setPage}) => {
    return (
        <div className="valuation-sidebar">
            <h2 className="valuation-sidebar-header">Valuation</h2>
            <ul className="valuation-menu-list">
                <li>
                    <button className="menu-btn" onClick={() => setPage('rcps')}>전환상환우선주(RCPS)</button>
                </li>
                <li>
                    <button className="menu-btn" onClick={() => setPage('cb')}>전환사채(CB)</button>
                </li>
                <li>
                    <button className="menu-btn" onClick={() => setPage('stockoption')}>주식매수선택권(Stock Option)</button>
                </li>
                <li>
                    <button className="menu-btn" onClick={() => setPage('optionbond')}>조기상환부사채/수의상환사채</button>
                </li>
                <li>
                    <button className="menu-btn" onClick={() => setPage('preemtiveright')}>신주인수권부사채(BW)</button>
                </li>
            </ul>
            <h3 className="valuation-sidebar-header">{"<지원 예정>"}</h3>
            <ul className="valuation-menu-ready-list">
                <li><button className="ready-function">리픽싱(Re-fixing)조건이 있는 경우</button></li>
                <li><button className="ready-function">몬테카를로 시뮬레이션</button></li>
                <li><button className="ready-function">영구채</button></li>
            </ul>
            <div className="logo-container">
            <img src="/Project-porto-initial.webp" alt="project-typo" className="logo-image"/>
            </div>
        </div>
    );
};

export default ValuationSidebar;
