import React, {useState} from "react";
import OptionContractInfo from "./OptionContractInfo.js";
import "./OnOffOpionContractInfo.css"


const OnOffOptionContractInfo = () => {
    const [activePutOption, setActivePutOption] = useState('off');
    const [activeCallOption, setActiveCallOption] = useState('off');

    const handleActivatePutOption = (optionType) => {
        setActivePutOption(prevOption => (prevOption === optionType ? 'off' : optionType));
    };

    const handleActiveCallOption = (optionType) => {
        setActiveCallOption(prevOption => (prevOption === optionType ? 'off' : optionType));
    }

    return (
        <>
            <div>
                <label>
                    <input type="checkbox" id="isPutOption" className="optionCheckBox"
                           onChange={e => handleActivatePutOption(e.target.checked ? 'on' : 'off')}/>
                    조기상환청구권
                    {/*<input*/}
                    {/*    type="checkbox"*/}
                    {/*    className="optionCheckBox"*/}
                    {/*    onChange={e => handleActivatePutOption(e.target.checked ? 'on' : 'off')}*/}
                    {/*/>*/}
                </label>
            </div>
            {
                activePutOption === 'on' && (
                    <OptionContractInfo type="PUT"/>
                )
            }
            <br/>
            {/*<div>*/}
            {/*    <button type="button" onClick={() => handleActivatePutOption('on')}> 조기상환청구권 정보 입력 / 제거</button>*/}
            {/*</div>*/}
            {/*    {*/}
            {/*        activePutOption === 'on' && (*/}
            {/*            <OptionContractInfo type="PUT"/>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    <br/>*/}
            <div>
                <input
                    type="checkbox"
                    className="optionCheckBox"
                    onChange={e => handleActiveCallOption(e.target.checked ? 'on' : 'off')}
                />
                수의상환권 정보
            </div>
            {/*<div>*/}
            {/*    <button type="button" onClick={() => handleActiveCallOption('on')}> 수의상환권 정보 입력 / 제거</button>*/}
            {/*</div>*/}
            {
                activeCallOption === 'on' && (
                    <OptionContractInfo type="CALL"/>
                )
            }
        </>
    )
}

export default OnOffOptionContractInfo;