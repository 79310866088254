import React from 'react';
import './ValuationContent.css'

const ValuationContent = () => {
    return (
        <div className="valuation-index">
            Content
        </div>
    );
};

export default ValuationContent;
