import React, { useState, useEffect } from 'react';

const NumberInput = ({ name, defaultValue }) => {
    const [displayValue, setDisplayValue] = useState('');
    const [numericValue, setNumericValue] = useState('');
    const [hasFocus, setHasFocus] = useState(false);

    useEffect(() => {
        if (defaultValue) {
            const formattedValue = formatNumber(defaultValue.toString());
            setDisplayValue(formattedValue);
            setNumericValue(defaultValue);
        }
    }, [defaultValue]);

    const handleInputChange = (event) => {
        let { value } = event.target;
        const rawValue = value.replace(/,/g, '');
        if (!isNaN(rawValue) && /^[0-9]*[.]?[0-9]*$/.test(rawValue)) {
            setDisplayValue(formatNumber(rawValue));
            setNumericValue(rawValue);
        } else {
            setDisplayValue('');
            setNumericValue('');
        }
    };

    const handleFocus = (event) => {
        if (!hasFocus) {
            setDisplayValue('');
            setHasFocus(true);
        } else {
            let {value} = event.target;
            setDisplayValue(value.replace(/,/g, ''));
        }
    };

    const handleBlur = (event) => {
        let { value } = event.target;
        if (!isNaN(value) && value !== '') {
            setDisplayValue(formatNumber(value));
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        navigator.clipboard.readText()
            .then(text => {
                const rawValue = text.replace(/,/g, '').trim();
                if (!isNaN(rawValue) && /^[0-9]*[.]?[0-9]*$/.test(rawValue)) {
                    setDisplayValue(formatNumber(rawValue));
                    setNumericValue(rawValue);
                }
            })
            .catch(err => console.error('Failed to read clipboard contents: ', err));
    };

    const formatNumber = (value) => {
        const parts = value.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    };

    return (
        <>
            <input
                type="text"
                value={displayValue}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onPaste={handlePaste}
                style={{ textAlign: 'right' }}
                required
            />
            <input
                type="hidden"
                name={name}
                value={numericValue}
            />
        </>
    );
};

export default NumberInput;
