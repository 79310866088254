import React, {useState} from "react";
import ValuationSidebar from "./Sidebar/ValuationSidebar.js";
import ValuationContent from "./Content/ValuationContent.js";
import "./ValuationMain.css";
import Rcps from "./Content/Rcps.js";
import Cb from "./Content/Cb.js";
import OptionBond from "./Content/OptionBond.js";
import StockOption from "./Content/StockOption.js";
import PreemtiveRight from "./Content/PreemtiveRight.js";

function ValuationMain() {
    const [page, setValuationPage] = useState('default');

    const renderContent = () => {
        switch (page) {
            case 'optionbond':
                return <OptionBond/>
            case 'rcps':
                return <Rcps/>
            case 'cb':
                return <Cb/>
            case 'stockoption':
                return <StockOption />
            case 'preemtiveright':
                return <PreemtiveRight />
            default:
                return <ValuationContent/>
        }
    }

    return (
        <div className="valuation-main">
            <ValuationSidebar setPage={setValuationPage}/>
            <div className="valuation-container">
                <div className="valuation-content">
                    {renderContent()}
                </div>
            </div>
        </div>
    );
}

export default ValuationMain;