import React, { useEffect, useRef } from 'react';
import './YtmTable.css';

const YtmTable = ({ tableData, setTableData, tableName, tableTitle }) => {
    const tableRef = useRef(null);

    const handleAddRow = () => {
        setTableData(prevTable => [...prevTable, { period: '', rate: '' }]);
    };

    const handleRemoveRow = (index) => {
        setTableData(prevTable => prevTable.filter((_, i) => i !== index));
    };

    const handleChange = (index, key, value) => {
        setTableData(prevTable => {
            const newTable = [...prevTable];
            newTable[index][key] = value;
            return newTable;
        });
    };

    const handlePaste = (event) => {
        event.preventDefault();
        navigator.clipboard.readText()
            .then(text => {
                const rows = text.split(/[\r\n]+/); // 줄바꿈으로 분리 (Windows와 MacOS 모두 호환)
                const parsedData = rows.map(row => {
                    const [period, rate] = row.split(/\t+/); // 탭으로 분리
                    // 숫자 형식으로 변환 및 유효성 검사
                    const parsedPeriod = parseFloat(period);
                    const parsedRate = parseFloat(rate);
                    if (!isNaN(parsedPeriod) && !isNaN(parsedRate)) {
                        return { period: parsedPeriod, rate: parsedRate };
                    }
                    return null;
                }).filter(row => row !== null); // 유효한 데이터만 필터링
                setTableData(parsedData);
            })
            .catch(err => console.error('Failed to read clipboard contents: ', err));
    };

    useEffect(() => {
        const tableElement = tableRef.current;
        if (tableElement) {
            tableElement.addEventListener('paste', handlePaste);
        }
        return () => {
            if (tableElement) {
                tableElement.removeEventListener('paste', handlePaste);
            }
        };
    }, [tableRef.current]);

    return (
        <div className="rate-table">
            <h2>{tableTitle}</h2>
            <p className="rate-table-description">* 엑셀 이자율 표 복사 / 붙여넣기 가능</p>
            <table ref={tableRef} id={tableName} className="table-content">
                <thead>
                <tr>
                    <th>기간(연)</th>
                    <th>평가기준일 할인율(%)</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((row, index) => (
                    <tr key={index}>
                        <td>
                            <input
                                type="text"
                                pattern="^\d+(\.\d+)?$"
                                name={`${tableName}Period[]`}
                                value={row.period}
                                onChange={(e) => handleChange(index, 'period', e.target.value)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                // step="0.01"
                                pattern="^\d+(\.\d+)?$"
                                name={`${tableName}Rate[]`}
                                value={row.rate}
                                onChange={(e) => handleChange(index, 'rate', e.target.value)}
                            />
                        </td>
                        <td className="buttonTd">
                            <button type="button" className="delete-button" onClick={() => handleRemoveRow(index)}>X
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button type="button" onClick={handleAddRow}>행 추가</button>
        </div>
    );
};

export default YtmTable;

