import React from "react";
import NumberInput from "./NumberInput.js";

const Volatility = () => {
    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <td className="key">이자율 변동성</td>
                    <td colSpan="2">
                        <NumberInput name={"volatility"} required defaultValue="1"/> %
                        {/*<input type="number" step="0.01" name={"volatility"} required defaultValue="1"/> %*/}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Volatility;