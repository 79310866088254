import React, {useEffect, useState} from 'react';
import './App.css';
import Header from "./components/Header/Header.js";
import ContactPage from "./components/Content/ContactPage.js";
import ValuationMain from "./components/Valuation/ValuationMain.js";

function App() {
    const [page, setPage] = useState('home');

    useEffect(() => {
        if (page === 'valuation') {
            window.location.reload();
        }
    }, [page]);

    const renderContent = () => {
        switch(page) {
            case 'contact':
                return <ContactPage />;
            case 'valuation':
                return <ValuationMain />;
            default:
                return <ValuationMain />;
        }
    }

    return (
        <div className="App">
            <Header setPage={setPage}/>
            <div className="app-content">
                {renderContent()}
            </div>
        </div>
    );
}

export default App;
