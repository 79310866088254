import React from 'react';
import './ContractInfo.css'
import NumberInput from "./NumberInput.js";
import CustomDateInput from "./CustomDate.js";
const ContractInfo = () => {
    return (
        <div className="ContractInfo">
            <table>
                <tbody>
                <tr>
                    <th colSpan="3">계약 정보</th>
                </tr>
                <tr>
                    <td className="key">계약일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-startDate" defaultValue="2024-07-01" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">만기일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-endDate" defaultValue="2024-07-08" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">평가기준일</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-valuationDate" defaultValue="2024-07-05" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">액면금액</td>
                    <td colSpan="2">
                        <NumberInput name="contract-parAmount" defaultValue="200000000" /> 원
                    </td>
                </tr>
                <tr>
                    <td className="key">액면이자율</td>
                    <td colSpan="2">
                        <NumberInput name="contract-couponPercent" defaultValue="1" /> %
                    </td>
                </tr>
                <tr>
                    <td className="key">이자지급주기</td>
                    <td colSpan="2">
                        <select name="contract-frequency" required>
                            <option value="MONTHLY">월</option>
                            <option value="QUARTERLY">분기</option>
                            <option value="ANNUALLY">연</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td className="key">만기보장수익률</td>
                    <td colSpan="2">
                        <NumberInput name="contract-maturityPremiumPercent" defaultValue="2" /> %
                        {/*<input type="number" step="0.01" name="contract-maturityPremiumPercent" required*/}
                        {/*       defaultValue="2"/> %*/}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ContractInfo;