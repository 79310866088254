import React from 'react';
import './Header.css';

function Header({setPage}) {
    return (
        <div className="header">
            <nav className="header-nav">
                <ul>
                    <li className="header-nav-link">
                        <button className="header-nav-link-btn" onClick={() => setPage('home')}>HOME</button>
                    </li>
                    <li className="header-nav-link">
                        <button className="header-nav-link-btn" onClick={() => setPage('valuation')}>VALUATION</button>
                    </li>
                    <li className="header-nav-link">
                        <button className="header-nav-link-btn" onClick={() => setPage('contact')}>CONTACT US</button>
                    </li>
                </ul>
            </nav>
        </div>
    );
}


export default Header;
