import React from 'react';
import NumberInput from "./NumberInput.js";

const Stock = ({stockInput, stockInputName}) => {
    return (
        <div>
            <table>
                <tbody>
                <tr>
                    <th colSpan="3">평가 정보</th>
                </tr>
                <tr>
                    <td className="key">1주당 기초자산 주가</td>
                    <td colSpan="2">
                        <NumberInput name="stock-price" defaultValue="20000"/> 원
                    </td>
                </tr>
                <tr>
                    <td className="key">기초자산 주가변동성</td>
                    <td colSpan="2">
                        <NumberInput name="stock-volatility" defaultValue="54.8"/> %
                    </td>
                </tr>
                <tr>
                    <td className="key">배당 수익률</td>
                    <td colSpan="2">
                        <NumberInput name="dividendYieldPercent" defaultValue="0"/>%
                    </td>
                </tr>
                {stockInput && (
                    <tr>
                        <td className="key">{stockInputName}</td>
                        <td colSpan="2">
                            <NumberInput name="shareCount" defaultValue="54"/> 주
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    )
}

export default Stock;