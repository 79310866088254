import { useEffect } from 'react';

export function addCommasToNumber(number) {
    if (number === undefined || number === null) {
        return '';
    }
    var biggerThanZero = number.toString().split('.')[0];
    return biggerThanZero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}


export const usePreventNegativeInput = () => {
    useEffect(() => {
        const handleInputChange = (e) => {
            const { value } = e.target;
            if (value === '' || (Number(value) >= 0 && !value.includes('e') && !value.includes('-'))) {
                e.target.value = value;
            } else {
                e.target.value = '';
            }
        };

        const handlePaste = (e) => {
            const paste = (e.clipboardData || window.clipboardData).getData('text');
            if (paste.includes('-') || isNaN(paste)) {
                e.preventDefault();
            }
        };

        const forms = document.querySelectorAll('form');
        forms.forEach(form => {
            const inputs = form.querySelectorAll('input[type="number"]');
            inputs.forEach(input => {
                input.addEventListener('input', handleInputChange);
                input.addEventListener('paste', handlePaste);
            });
        });

        return () => {
            forms.forEach(form => {
                const inputs = form.querySelectorAll('input[type="number"]');
                inputs.forEach(input => {
                    input.removeEventListener('input', handleInputChange);
                    input.removeEventListener('paste', handlePaste);
                });
            });
        };
    }, []);
};
