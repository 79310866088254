import React from 'react';
import '../Rcps.css'
import NumberInput from "./NumberInput.js";
import CustomDateInput from "./CustomDate.js";

const OptionContractInfo = ({type}) => {
    return (
        <div>
            <table>
                <tbody>
                <tr>
                    {
                        type === undefined && (
                            <th colSpan="3">{'옵션 정보 (조기상환청구권/수의상환권)'}</th>
                        )}
                    {
                        type !== undefined && (
                            <th colSpan="3">
                                {'옵션 정보 (' + (type.toLowerCase() === 'put' ? '조기상환청구권' : type.toLowerCase() === 'call' ? '수의상환권' : type) + ')'}
                            </th>
                        )
                    }
                </tr>
                {
                    type === undefined && (
                        <tr>
                            <td className="key">옵션 유형</td>
                            <td colSpan="2">
                                {/*<select name="option-default-type">*/}
                                <select name={"option-" + type + "-type"} required>
                                    <option value="PUT">조기상환부사채</option>
                                    <option value="CALL">수의상환사채</option>
                                </select>
                            </td>
                        </tr>
                    )
                }
                <tr>
                    <td className="key">행사시작일자</td>
                    <td colSpan="2">
                        <CustomDateInput name={"option-" + type + "-startDate"} defaultValue="2024-07-01" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">행사종료일자</td>
                    <td colSpan="2">
                        <CustomDateInput name={"option-" + type + "-endDate"} defaultValue="2024-07-02" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">옵션행사수익률(연복리 상환가액)</td>
                    <td colSpan="2">
                        <NumberInput name={"option-" + type + "-yieldPercent"} defaultValue="1"/> %
                    </td>
                </tr>
                <tr>
                    <td className="key">행사가능주기</td>
                    <td colSpan="2">
                        <select name={"option-" + type + "-frequency"} required>
                            {/*<option value="ALWAYS">항상</option>*/}
                            <option value="WEEKLY">주</option>
                            <option value="MONTHLY">월</option>
                            <option value="QUARTERLY">분기</option>
                            <option value="ANNUALLY">연</option>
                        </select>
                    </td>
                </tr>
                <tr>
                    <td className="key">경과이자 지급조건</td>
                    <td colSpan="2">
                        <select name={"option-" + type + "-unpaidInterest"} required>
                            <option value="false">지급 안함</option>
                            <option value="true">지급 함</option>
                        </select>
                    </td>
                </tr>

                {
                    type !== undefined && (
                        <input type="hidden" name={"option-" + type + "-type"} value={type}/>
                    )
                }

                </tbody>
            </table>
            {/*<input type="hidden" name={"option-" + type + "-type"} value={type}/>*/}
        </div>
    )
}

export default OptionContractInfo;