import React, {useState} from "react";
import Stock from "./Input/Stock.js";
import TimeStep from "./Input/TimeStep.js";
import BondWithWarranty from "./Input/BondWithWarranty.js";
import OnOffOpionContractInfo from "./Input/OnOffOpionContractInfo.js";
import YtmTable from "./Input/YtmTable.js";
import "./Cb.css";
import {addCommasToNumber, usePreventNegativeInput} from "./Utils/NumberFormatUtils.js";
import {validateContractDate, validateOptionDate} from "./Utils/Validator.js";
import {processTableData} from "./Utils/TableUtil.js";
import ContractInfo from "./Input/ContractInfo.js";


const Cb = () => {
    usePreventNegativeInput()
    const [ytmTable, setYtmTable] = useState([
        {period: 0.5, rate: 0},
        {period: 1.0, rate: 0},
        {period: 1.5, rate: 0},
        {period: 2.0, rate: 0},
        {period: 2.5, rate: 0},
        {period: 3.0, rate: 0},
        {period: 3.5, rate: 0},
        {period: 4.0, rate: 0},
        {period: 5.0, rate: 0}
    ]);

    const [riskFreeYtmTable, setRiskFreeYtmTable] = useState([
        {period: 0.5, rate: 0},
        {period: 1.0, rate: 0},
        {period: 1.5, rate: 0},
        {period: 2.0, rate: 0},
        {period: 2.5, rate: 0},
        {period: 3.0, rate: 0},
        {period: 3.5, rate: 0},
        {period: 4.0, rate: 0},
        {period: 5.0, rate: 0}
    ]);

    const [responseData, setResponseData] = useState({});
    const [inputValidation, setInputValidation] = useState({});
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage("");
        setInputValidation(undefined)

        const form = event.target;
        const formData = new FormData(form);
        const jsonData = {};

        const bwData = {};
        const optionDataList = [];
        const putOptionData = {};
        const callOptionData = {};
        const contractData = {};
        const stockData = {};

        for (let [key, value] of formData.entries()) {
            if (['ytm-tablePeriod[]', 'ytm-tableRate[]', 'riskfree-ytm-tablePeriod[]', 'riskfree-ytm-tableRate[]'].includes(key)) {
                continue
            }
            if (key.includes('bw-')) {
                bwData[key.replace("bw-", "")] = value;
                continue
            } else if (key.includes('option-')) {
                let optionKey = key.replace("option-", "");
                if (optionKey.includes("PUT")) {
                    putOptionData[optionKey.replace("PUT-", "")] = value;
                } else if (optionKey.includes("CALL")) {
                    callOptionData[optionKey.replace("CALL-", "")] = value;
                }
                continue
            } else if (key.includes('contract-')) {
                contractData[key.replace("contract-", "")] = value;
                continue
            } else if (key.includes('stock-')) {
                stockData[key.replace("stock-", "")] = value;
                continue
            }

            if (jsonData[key]) {
                if (!Array.isArray(jsonData[key])) {
                    jsonData[key] = [jsonData[key]];
                }
                jsonData[key].push(value);
            } else {
                jsonData[key] = value;
            }
        }
        jsonData.bondWithWarrantyContract = bwData
        if (Object.keys(putOptionData).length > 0) {
            optionDataList.push(putOptionData);
        }
        if (Object.keys(callOptionData).length > 0) {
            optionDataList.push(callOptionData);
        }
        jsonData.optionContracts = optionDataList
        jsonData.bondContractBody = contractData
        jsonData.stock = stockData;
        jsonData.riskyYtmTable = processTableData(ytmTable);
        jsonData.riskyFreeYtmTable = processTableData(riskFreeYtmTable);

        const validationResult = validateInput(jsonData)
        setInputValidation(validationResult)
        if (!validationResult.valid) {
            console.log('validation error');
            setLoading(false);
            return;
        }

        try {
            // const response = await fetch('https://localhost:8080/jsvaluation/convertibleBondValuation', {
            // const response = await fetch('https://api.jisungvalue.com/jsvaluation/convertibleBondValuation', {
            const response = await fetch('https://api.project-porto.com/jsvaluation/convertibleBondValuation', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            });

            if (!response.ok) {
                if (response.status >= 400 && response.status < 500) {
                    setErrorMessage("클라이언트 오류 발생: 요청을 확인하세요.");
                } else if (response.status >= 500) {
                    setErrorMessage("서버 오류 발생: 잠시 후 다시 시도하세요.");
                }
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setResponseData(responseData);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setResponseData({error: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="cb">
            <div className="cb-container">
                <div className="form-wrapper">
                    <form id="evaluationForm" onSubmit={handleSubmit}>
                        <div className="cb-input-area">
                            <h2>전환사채(CB) 평가</h2>
                            <ContractInfo/>
                            <br/>
                            <OnOffOpionContractInfo/>
                            <br/>
                            <BondWithWarranty optionName="전환권" needConvertibleRatio={true}/>
                            <br/>
                            <Stock/>
                            <br/>
                            {/*<DividendYield/>*/}
                            <br/>
                            <TimeStep/>
                            <br/>
                        </div>
                        <div className="cb-ytm-area">
                            <YtmTable
                                tableData={ytmTable}
                                setTableData={setYtmTable}
                                tableName="ytm-table"
                                tableTitle="위험할인율(YTM)"
                            />

                            <YtmTable
                                tableData={riskFreeYtmTable}
                                setTableData={setRiskFreeYtmTable}
                                tableName="riskfree-ytm-table"
                                tableTitle="무위험할인율(YTM)"
                            />
                        </div>
                        <button type="submit">평가하기</button>
                    </form>
                </div>

                <div className="response-data-wrapper">
                    <div className="response-data">
                        <h2>평가 결과</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {(inputValidation.valid === undefined) ? "" :
                                        addCommasToNumber(responseData.rcpsFairValue)
                                    }
                                </td>
                            </tr>
                            {inputValidation.valid !== undefined && !inputValidation.valid && (
                                <tr>
                                    <td className="error-message">
                                        {"오류: " + inputValidation.errorMessage}
                                    </td>
                                </tr>
                            )

                            }
                            {errorMessage !== "" && (
                                <tr>
                                    <td className="error-message">
                                        {errorMessage}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <h2>평가 모형 | T-F 모형</h2>
                        <h3>참조사항</h3>
                        <ul>
                            <li>평가시 기초주가에 희석효과가 반영된 것으로 가정하였습니다. (주가 이항트리상 희석효과를 고려하지 않음)
                            </li>
                            <li>주계약 일반사채(혹은 옵션부사채) 평가는 [조기상환사채&수의상환사채] 평가 기능에서 산출 가능합니다.
                            </li>
                            <li>본 사이트는 상기 평가 결과에 대하여 어떠한 책임도 지지 않습니다.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="loading-screen">
                    <div className="progress-bar">
                        <div className="progress"></div>
                    </div>
                    <p>계산 중 입니다. 잠시 기다려 주세요.</p>
                </div>
            )}
        </div>
    );
}


function validateInput(jsonData) {
    let [valid, invalidMessage] = validateContractDate(jsonData.bondContractBody)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    [valid, invalidMessage] = validateOptionValue(jsonData.bondContractBody, jsonData.optionContracts)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    [valid, invalidMessage] = validateBwContract(jsonData.bondContractBody, jsonData.bondWithWarrantyContract)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    return {"valid": valid, "errorMessage": invalidMessage}
}

function validateOptionValue(contract, optionList) {
    if (optionList === undefined || optionList.length === 0) {
        return [false, "조기상환청구권 혹은 수의상환권 정보가 필요 합니다"];
    }
    let valid = true
    let invalidMessage = ""
    optionList.forEach(option => {
        let optionName;
        if (option.type === "PUT") {
            optionName = "조기상환청구권"
        } else {
            optionName = "수의상환권"
        }
        [valid, invalidMessage] = validateOptionDate(contract, option, optionName);
    })
    return [valid, invalidMessage]
}

function validateBwContract(contract, bwContract) {
    let [valid, invalidMessage] = validateOptionDate(contract, bwContract, "전환권");
    return [valid, invalidMessage]
}

export default Cb;