import React, { useState } from 'react';

const CustomDateInput = ({ name, defaultValue, required }) => {
    const [date, setDate] = useState(defaultValue);
    const [hasFocus, setHasFocus] = useState(false);

    const handleDateChange = (event) => {
        let value = event.target.value.replace(/[^0-9]/g, '');
        if (value.length > 4 && value.length <= 6) {
            value = value.slice(0, 4) + '-' + value.slice(4);
        } else if (value.length > 6) {
            value = value.slice(0, 4) + '-' + value.slice(4, 6) + '-' + value.slice(6, 8);
        }
        setDate(value);
    };

    const handleFocus = (event) => {
        if (!hasFocus) {
            setDate('');
            setHasFocus(false);
        }
    };

    return (
        <input
            type="text"
            name={name}
            value={date}
            onChange={handleDateChange}
            onFocus={handleFocus}
            placeholder="YYYY-MM-DD"
            maxLength="10"
            required={required}
        />
    );
};

export default CustomDateInput;
