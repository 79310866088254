import React, {useState} from 'react';
import './StockOption.css';
import './Common.css';
import Stock from "./Input/Stock.js";
import TimeStep from "./Input/TimeStep.js";
import BondWithWarranty from "./Input/BondWithWarranty.js";
import YtmTable from "./Input/YtmTable.js";
import ContractDate from "./Input/ContractDate.js";
import {addCommasToNumber, usePreventNegativeInput} from "./Utils/NumberFormatUtils.js";
import {processTableData} from "./Utils/TableUtil.js";
import {validateContractDate, validateOptionDate} from "./Utils/Validator.js";

const StockOption = () => {
    usePreventNegativeInput()

    const [riskFreeYtmTable, setRiskFreeYtmTable] = useState([
        {period: 0.5, rate: 0},
        {period: 1.0, rate: 0},
        {period: 1.5, rate: 0},
        {period: 2.0, rate: 0},
        {period: 2.5, rate: 0},
        {period: 3.0, rate: 0},
        {period: 3.5, rate: 0},
        {period: 4.0, rate: 0},
        {period: 5.0, rate: 0}
    ]);

    const [responseData, setResponseData] = useState({});
    const [inputValidation, setInputValidation] = useState({});
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrorMessage("");
        setInputValidation(undefined)

        const form = event.target;
        const formData = new FormData(form);
        const jsonData = {};

        const bwData = {};
        const optionDataList = [];
        const stockData = {};

        for (let [key, value] of formData.entries()) {
            if (['ytm-tablePeriod[]', 'ytm-tableRate[]', 'riskfree-ytm-tablePeriod[]', 'riskfree-ytm-tableRate[]'].includes(key)) {
                continue
            }
            if (key.includes('bw-')) {
                bwData[key.replace("bw-", "")] = value;
                continue
            } else if (key.includes('contract-')) {
                jsonData[key.replace("contract-", "")] = value;
                continue
            } else if (key.includes('stock-')) {
                stockData[key.replace("stock-", "")] = value;
                continue
            }

            if (jsonData[key]) {
                if (!Array.isArray(jsonData[key])) {
                    jsonData[key] = [jsonData[key]];
                }
                jsonData[key].push(value);
            } else {
                jsonData[key] = value;
            }
        }
        jsonData.bondWithWarrantyContract = bwData
        jsonData.optionContracts = optionDataList
        jsonData.stock = stockData;
        // 무위험인데 서버에서 risky 로 받음. 수정 필요..
        jsonData.riskyYtmTable = processTableData(riskFreeYtmTable);

        // console.log(JSON.stringify(jsonData, null, 2));

        const validationResult = validateInput(jsonData)
        setInputValidation(validationResult)
        if (!validationResult.valid) {
            console.log('validation error');
            setLoading(false);
            return;
        }

        try {
            // const response = await fetch('https://api.jisungvalue.com/jsvaluation/bondWithWarrantyValuation', {
            const response = await fetch('https://api.project-porto.com/jsvaluation/bondWithWarrantyValuation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(jsonData),
            });

            if (!response.ok) {
                if (response.status >= 400 && response.status < 500) {
                    setErrorMessage("클라이언트 오류 발생: 요청을 확인하세요.");
                } else if (response.status >= 500) {
                    setErrorMessage("서버 오류 발생: 잠시 후 다시 시도하세요.");
                }
                throw new Error("Network response was not ok");
            }

            const responseData = await response.json();
            console.log('Response:', responseData);
            setResponseData(responseData);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setResponseData({error: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="stock" >
            <div className="stock-container" >
                <div className="form-wrapper" >
                    <form id="evaluationForm" onSubmit={handleSubmit}>
                        <div className="stock-input-area">
                            <h2>주식매수선택권(Stock Option) 평가</h2>
                            <ContractDate/>
                            <br/>
                            <BondWithWarranty optionName="주식매수선택권" needConvertibleRatio={false}/>
                            <br/>
                            <Stock stockInput={true} stockInputName="주식매수선택권 행사가능주식수"/>
                            <br/>
                            {/*<DividendYield/>*/}
                            <br/>
                            <TimeStep/>
                            <br />
                        </div>
                        <div className="stock-ytm-area">
                            <YtmTable
                                tableData={riskFreeYtmTable}
                                setTableData={setRiskFreeYtmTable}
                                tableName="riskfree-ytm-table"
                                tableTitle="무위험할인율(YTM)"
                            />
                        </div>
                        <button type="submit">평가하기</button>
                    </form>
                </div>

                {/*    /!* 응답 데이터를 화면에 출력 *!/*/}
                {/*    /!*{responseData && (*!/*/}
                <div className="response-data-wrapper">
                    <div className="response-data">
                        <h2>평가 결과</h2>
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    {(inputValidation.valid === undefined) ? "" :
                                        addCommasToNumber(responseData.totalBondWithWarrantyFairValue)
                                    }
                                </td>
                            </tr>
                            {inputValidation.valid !== undefined && !inputValidation.valid && (
                                <tr>
                                    <td className="error-message">
                                        {"오류: " + inputValidation.errorMessage}
                                    </td>
                                </tr>
                            )

                            }
                            {errorMessage !== "" && (
                                <tr>
                                    <td className="error-message">
                                        {errorMessage}
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                        <h2>평가 모형 | CRR 이항 모형</h2>
                        <h3>참조사항</h3>
                        <ul>
                            <li>평가 결과 산출시 기초주가에 희석효과가 반영된 것으로 가정하였습니다. (주가 이항트리상 희석효과를 고려하지 않음)</li>
                            <li>본 사이트는 상기 평가 결과에 대하여 어떠한 책임도 지지 않습니다.</li>
                        </ul>
                    </div>
                </div>
                {/*</div>*/}
                {/*)}*/}
            </div>
            {loading && (
                <div className="loading-screen">
                    <div className="progress-bar">
                        <div className="progress"></div>
                    </div>
                    <p>계산 중 입니다. 잠시 기다려 주세요.</p>
                </div>
            )}
        </div>
    );
};

function validateInput(jsonData) {
    let bondContractBody = {
        "startDate": jsonData.startDate,
        "endDate": jsonData.endDate,
        "valuationDate": jsonData.valuationDate
    }
    let [valid, invalidMessage] = validateContractDate(bondContractBody)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    [valid, invalidMessage] = validateBwContract(bondContractBody, jsonData.bondWithWarrantyContract)
    if (valid === false) {
        return {"valid": valid, "errorMessage": invalidMessage}
    }

    return {"valid": valid, "errorMessage": invalidMessage}
}

function validateBwContract(contract, bwContract) {
    let [valid, invalidMessage] = validateOptionDate(contract, bwContract, "주식매수선택권");
    return [valid, invalidMessage]
}


export default StockOption;
