import React from 'react';
import './ContractInfo.css'
import CustomDateInput from "./CustomDate.js";

const ContractDate = () => {
    return (
        <div className="ContractInfo">
            <table>
                <tbody>
                <tr>
                    <th colSpan="3">계약 정보</th>
                </tr>
                <tr>
                    <td className="key">계약일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-startDate" defaultValue="2024-07-01" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">만기일자</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-endDate" defaultValue="2024-07-08" required/>
                    </td>
                </tr>
                <tr>
                    <td className="key">평가기준일</td>
                    <td colSpan="2">
                        <CustomDateInput name="contract-valuationDate" defaultValue="2024-07-05" required/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ContractDate;